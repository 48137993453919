var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"left_tree_container"},[(_vm.isDragging)?_c('div',{staticClass:"cover-box"}):_vm._e(),_c('div',{ref:"tree_container_sty",staticClass:"tree_container_sty",style:({ height: _vm.topHeight + 'px' })},[_c('vue-easy-tree',{staticClass:"leftTree",attrs:{"props":{
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },"lazy":"","data":_vm.treeData,"height":(_vm.topHeight + "px"),"node-key":"artifactPath","icon-class":"el-icon-arrow-right","load":function (treeNode, resolve) { return _vm.onLoadData(treeNode,resolve, false); },"selectedKeys":_vm.selectedKeys,"expandedKeys":_vm.expandedKeys},on:{"node-click":function (data){ return _vm.treeSelect(data,false); },"node-contextmenu":_vm.onRightClick,"node-expand":function (data, treeNode) { return _vm.onExpand(data, treeNode, false); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
            var node = ref.node;
return [_c('div',{staticClass:"title_box"},[_c('span',[(node.loading && !node.expanded)?_c('a-icon',{style:({color: '#1890ff'}),attrs:{"type":"loading"}}):_vm._e(),(data.type === 'dir' || data.type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":node.expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"8px"},style:(data.type === 'recycle'? 'color:#393b3e':''),attrs:{"type":_vm.getIconType(data.name, data.type)}}),_c('span',{staticClass:"tree_title",style:(data.type === 'recycle'? 'color:#393b3e':'')},[_vm._v(" "+_vm._s(data.name)+" ")])],1)])]}}])})],1),_c('div',{staticClass:"line-box",class:_vm.isDragging ? 'line-drag' : '',on:{"mousedown":_vm.startDragging}}),_c('div',{ref:"tree_container_sty",staticClass:"tree_container_sty recycle",style:({ height: _vm.bottomHeight + 'px' })},[_c('vue-easy-tree',{staticClass:"leftTree",attrs:{"props":{
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },"lazy":"","data":_vm.trashData,"height":(_vm.bottomHeight + "px"),"node-key":"artifactPath","load":function (treeNode,resolve) { return _vm.onLoadData(treeNode, resolve, true); },"selectedKeys":_vm.selectRecycleKeys,"expandedKeys":_vm.expandedRecycleKeys},on:{"node-click":function (data){ return _vm.treeSelect(data,true); },"node-contextmenu":_vm.onRightClick,"node-expand":function (expandedKeys, treeNode) { return _vm.onExpand(expandedKeys, treeNode, true); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
            var node = ref.node;
return [_c('div',{staticClass:"title_box"},[_c('span',[(node.loading && !node.expanded)?_c('a-icon',{style:({color: '#1890ff'}),attrs:{"type":"loading"}}):_vm._e(),(data.type === 'dir' || data.type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":node.expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"8px"},style:(data.type === 'recycle'? 'color:#393b3e':''),attrs:{"type":_vm.getIconType(data.name, data.type)}}),_c('span',{staticClass:"tree_title",style:(data.type === 'recycle'? 'color:#393b3e':'')},[_vm._v(" "+_vm._s(data.title || data.name)+" ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }